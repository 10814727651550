.buildingStateAddNodeModal {
    float: left;


}

.buildingStateAddNodeModalUl {
    li {
        margin-bottom: 10px;

    }
}

.buildingStateAddNodeModalSeletNode {
    .ant-tree-title {
        font-size: 10px;
    }
}


.hide {
    display: none;
}