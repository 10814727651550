.login {
    position: absolute;
    // width: 1900px;
    // height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .loginContainer {
        width: 380px;
        height: 300px;
        // background: rgba(51, 165, 255, 0.05);
        position: absolute;
        // left: 0;
        right: 10%;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 10px;
        z-index: 9;

        // border-radius: 4px;
        // border: 1px solid rgba(255, 255, 255, 0.5);
        .loginBg {
            width: 100%;
            height: 100%;
        }

        .loginTop {
            position: absolute;
            top: -9px;
            left: 0;
            width: 100%;
            height: 8px;
        }

        .loginBottom {
            position: absolute;
            bottom: -9px;
            left: 0;
            width: 100%;
            height: 1px;
        }

        .loginFrom {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 5px;
            right: 5px;
            z-index: 10;
            // margin: auto;


            p {
                font-size: 26px;
                font-family: 'MicrosoftYaHei-Bold';
                font-weight: bold;
                color: rgba(250, 240, 200, 1);
                // line-height: 9px;
                float: left;
                width: 100%;
                text-align: center;
                margin-top: 20px;

                span {
                    font-size: 15px;
                    font-family: 'ArialNarrow';
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 5px;
                }
            }

            input {
                width: 320px;
                height: 46px;
                // border: 1px solid rgba(255, 255, 255, 0.2);
                // margin-bottom: 4px;

                font-size: 18px;
                font-weight: 400;
                // color: rgba(242, 255, 255, 1);
                // line-height: 9px;
                // margin-left: 8px;
                // border-radius: 4px;
                // padding: 0 16px;
            }

            button {
                width: 320px;
                height: 46px;
                font-size: 18px;
                // border: 1px solid rgba(255, 255, 255, 0.3);
                // color: #F5F6FA;
                // margin-left: 30px;
                // margin-bottom: 20px;
                // border-radius: 4px;
            }

            // label {
            //     width: 320px;
            //     display: inline-block;

            //     input {
            //         float: left;
            //         width: 16px;
            //         height: 17px;
            //         border: 1px solid rgba(97, 104, 114, 1);
            //     }

            //     span {
            //         float: left;
            //         width: 64px;
            //         height: 16px;
            //         font-size: 16px;
            //         font-weight: 400;
            //         // color: rgba(255, 255, 255, 1);
            //         line-height: 18px;
            //         // opacity: 0.2;
            //         color: #ffffff;
            //     }
            // }
        }


    }

    .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
    }

    .graphics1 {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: auto;
        z-index: 2;
    }

    .graphics2 {
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        height: 100%;
        opacity: 0.7;
        z-index: 2;
    }

    .graphicsC1 {
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        height: 475px;
        z-index: 2;
    }

    .graphicsC2 {
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        height: 475px;
        z-index: 2;
    }

    .anime {
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        height: 475px;
        z-index: 2;
    }

    .anime1 {
        animation: anticlockwise 10s linear infinite;
        
    }
    .anime2 {
        animation: clockwise 10s linear infinite;
    }
    .anime3 {
        animation: anticlockwise 10s linear infinite;
    }
    .anime4 {
        animation: anticlockwise 10s linear infinite;
    }
    .anime5 {
        animation: clockwise 10s linear infinite;
        // transform: rotate(90deg);
        transform-origin: 140px 300px ;
        left: 250px;
        top: -158px;
        height: 435px
    }
    .anime6 {
        animation: anticlockwise 10s linear infinite;
    }
    .anime7 {
        animation: clockwise 10s linear infinite;
    }


    .large-header {
        // width: 100%;
        // height: 100%;
        float: left;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;

        #canvas {
            // height: 100%;
            // width: 100%;
            position: absolute;
            z-index: 46;
            left: 0;
            top: 0;
            z-index: 3;
        }
    }

    @keyframes clockwise {
        from {
            transform: rotate(0deg);
        }
       
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes anticlockwise {
        from {
            transform: rotate(360deg);
        }
       
        to {
            transform: rotate(0deg);
        }
    }
}