.buildingStateAddNode {
    display: flex;
    width: 100%;
    height: 100%;


    .buildingStateAddNodeRight {
        float: right;
        flex: 1;
        position: relative;

        .buildingStateAddNodeSearch,
        .buildingStateAddNodeTable {
            background-color: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            padding: 20px;
            border-radius: 4px;
        }

        .buildingStateAddNodeSearch {
            height: 72px;

            .buildingStateAddNodeSearchContainer {
                float: left;
            }
        }

        .buildingStateAddNodeTable {
            margin-top: 20px;
            position: absolute;
            top: 60px;
            bottom: 0;
            left: 0;
            right: 0;

            .buildingStateAddNodeTableHead {
                width: 100%;
                height: 32px;
                margin-bottom: 15px;

                .buildingStateAddNodeTableHeadLeft {
                    float: left;

                    button {
                        margin-right: 10px;
                    }
                }

                .buildingStateAddNodeTableHeadRight {
                    float: right;

                }
            }

            .ant-table-wrapper {
                .ant-btn-primary {
                    padding: 0 2px;
                    margin-right: 5px;
                }

                .ant-btn>.anticon+span {
                    margin-left: 0;
                }
            }

            .ant-table-thead>tr>th {
                background-color: #eff3f8;
            }

            .ant-checkbox {
                // box-shadow: 0 0 3px 0px #cacaca;
            }

            .ant-checkbox-inner {
                border-radius: 4px;
                // background-color: #cacaca;
            }

        }
    }

    .hide {
        display: none;
    }
}