.manager {

  width: 100%;
  height: 100%;
  background-color: #ffffff;
  //padding: 0 20px;

  //.DistrictManagementLeft {
  //    float: left;
  //    width: 210px;
  //    padding: 20px;
  //    height: 100%;
  //    margin-right: 20px;
  //    background-color: #ffffff;
  //    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  //
  //    .DistrictManagementLeftTreeHead {
  //        float: left;
  //        width: 100%;
  //
  //        span {
  //            float: left;
  //        }
  //
  //        i {
  //            float: right;
  //            line-height: 21px;
  //        }
  //    }
  //
  //    .ant-tree {
  //        float: left;
  //        width: 100%;
  //
  //        li {
  //            float: left;
  //
  //            span {
  //                float: left;
  //            }
  //        }
  //    }
  //}
  //
  //.DistrictManagementRight {
  //    float: right;
  //    flex: 1;
  //    position: relative;
  //
  //    .DistrictManagementRightTop,
  //    .DistrictManagementRightBottom {
  //        background-color: #ffffff;
  //        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  //        padding: 20px;
  //    }
  //
  //    .DistrictManagementRightBottom {
  //        margin-top: 20px;
  //        position: absolute;
  //        top: 60px;
  //        bottom: 0;
  //        left: 0;
  //        right: 0;
  //        // height: secal(100%-61px);
  //    }
  //}
  .ant-table-pagination.ant-pagination {
    margin-right: 20px;
  }
}