    .addNewAccountUl{
        li{
            margin-bottom: 10px;
            
            .label{
                line-height: 32px;
                float: left;
                width: 100%;
                text-align: right;
            }
        }
    }