.buildingStructureAddNodeModal {
    float: left;


}

.buildingStructureAddNodeModalUl {
    li {
        margin-bottom: 10px;

    }
}

.buildingStructureAddNodeModalSeletNode {
    .ant-tree-title {
        font-size: 10px;
    }
}


.hide {
    display: none;
}