.index {
    width: 1620px;
    height: 100%;
    background: #ffffff;

    padding: 0 20px;

    .addNewProject {
        float: left;
        width: 100%;
        text-align: left;
        margin-top: 10px;

        font-size: 14px;
        font-family: PingFang-SC-Regular;
        font-weight: 400;
        color: #3c4150;
        line-height: 30px;

        span {
            float: right;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: #464a59;
            line-height: 30px;
        }
    }

    .table {
        float: left;
        width: 100%;
        .blue{
            color:#4392F9;
        }
        .red{
            color:#4392F9;
        }
        .green{
            color:#4392F9;
        }
    }
}