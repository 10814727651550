.report {
    width: 1620px;
    height: 100%;
    background: #ffffff;

    padding: 0 20px;

    .addNewReport {
        float: left;
        width: 100%;
        text-align: left;
        margin-top: 10px;

        font-size: 14px;
        font-family: PingFang-SC-Regular;
        font-weight: 400;
        color: #3c4150;
        line-height: 30px;

        span {
            float: right;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: #464a59;
            line-height: 30px;
        }
    }



    .table {
        float: left;
        width: 100%;

        .blue {
            color: #4392F9;
        }

        .red {
            color: #FF2742;
        }

        .green {
            color: #33CF86;
        }

        td {
            div {
                float: left;
            }
        }
    }

    .hide {
        display: none;
    }
}

.edit-project {
    width: 920px !important;
    background: #ffffff;
}