@charset "UTF-8";
body {
  margin: 0;
  padding: 0;
  font-family: 'MicrosoftYaHei'-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F6FA;
  min-width: 1900px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

ul li {
  list-style: none; }

* {
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-style: normal; }

img {
  width: 30px;
  height: 30px; }

#root {
  width: 100%;
  height: 100%; }

html, body {
  min-height: 910px; }

::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0); }

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(91, 107, 115, 0.5);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0); }

.App {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;


}


.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  position: absolute;
  left: 240px;
  right: 0;
  top: 60px;
  bottom: 0;
  margin: 20px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.login {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }
  .login .loginContainer {
    width: 380px;
    height: 300px;
    position: absolute;
    right: 10%;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 10px;
    z-index: 9; }
    .login .loginContainer .loginBg {
      width: 100%;
      height: 100%; }
    .login .loginContainer .loginTop {
      position: absolute;
      top: -9px;
      left: 0;
      width: 100%;
      height: 8px; }
    .login .loginContainer .loginBottom {
      position: absolute;
      bottom: -9px;
      left: 0;
      width: 100%;
      height: 1px; }
    .login .loginContainer .loginFrom {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 5px;
      right: 5px;
      z-index: 10; }
      .login .loginContainer .loginFrom p {
        font-size: 26px;
        font-family: 'MicrosoftYaHei-Bold';
        font-weight: bold;
        color: #faf0c8;
        float: left;
        width: 100%;
        text-align: center;
        margin-top: 20px; }
        .login .loginContainer .loginFrom p span {
          font-size: 15px;
          font-family: 'ArialNarrow';
          font-weight: 400;
          color: white;
          line-height: 5px; }
      .login .loginContainer .loginFrom input {
        width: 320px;
        height: 46px;
        font-size: 18px;
        font-weight: 400; }
      .login .loginContainer .loginFrom button {
        width: 320px;
        height: 46px;
        font-size: 18px; }
  .login .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
  .login .graphics1 {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    z-index: 2; }
  .login .graphics2 {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    height: 100%;
    opacity: 0.7;
    z-index: 2; }
  .login .graphicsC1 {
    position: absolute;
    left: 0;
    top: 0px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: auto;
    height: 475px;
    z-index: 2; }
  .login .graphicsC2 {
    position: absolute;
    left: 0;
    top: 0px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: auto;
    height: 475px;
    z-index: 2; }
  .login .anime {
    position: absolute;
    left: 0;
    top: 0px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: auto;
    height: 475px;
    z-index: 2; }
  .login .anime1 {
    -webkit-animation: anticlockwise 10s linear infinite;
            animation: anticlockwise 10s linear infinite; }
  .login .anime2 {
    -webkit-animation: clockwise 10s linear infinite;
            animation: clockwise 10s linear infinite; }
  .login .anime3 {
    -webkit-animation: anticlockwise 10s linear infinite;
            animation: anticlockwise 10s linear infinite; }
  .login .anime4 {
    -webkit-animation: anticlockwise 10s linear infinite;
            animation: anticlockwise 10s linear infinite; }
  .login .anime5 {
    -webkit-animation: clockwise 10s linear infinite;
            animation: clockwise 10s linear infinite;
    -webkit-transform-origin: 140px 300px;
            transform-origin: 140px 300px;
    left: 250px;
    top: -158px;
    height: 435px; }
  .login .anime6 {
    -webkit-animation: anticlockwise 10s linear infinite;
            animation: anticlockwise 10s linear infinite; }
  .login .anime7 {
    -webkit-animation: clockwise 10s linear infinite;
            animation: clockwise 10s linear infinite; }
  .login .large-header {
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3; }
    .login .large-header #canvas {
      position: absolute;
      z-index: 46;
      left: 0;
      top: 0;
      z-index: 3; }

@-webkit-keyframes clockwise {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes clockwise {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes anticlockwise {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes anticlockwise {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.index .indexLeft {
  width: 50%;
  height: auto;
  float: left;
  margin-top: 50px; }
  .index .indexLeft h1 {
    color: #6188e4;
    margin-bottom: 50px; }
  .index .indexLeft img {
    width: 100%;
    height: 100%; }

.index .indexRight {
  float: left;
  margin-top: 50px;
  margin-left: 100px; }
  .index .indexRight h1 {
    color: #6188e4;
    margin-bottom: 170px; }
  .index .indexRight .Qrcode {
    width: 200px;
    height: 200px;
    margin: 0 20px;
    border: 1px solid #ccc;
    float: left; }
    .index .indexRight .Qrcode img {
      width: 100%;
      height: 100%; }

.manager {
  width: 100%;
  height: 100%;
  background-color: #ffffff; }
  .manager .ant-table-pagination.ant-pagination {
    margin-right: 20px; }

.report {
  width: 1620px;
  height: 100%;
  background: #ffffff;
  padding: 0 20px; }
  .report .addNewReport {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFang-SC-Regular;
    font-weight: 400;
    color: #3c4150;
    line-height: 30px; }
    .report .addNewReport span {
      float: right;
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #464a59;
      line-height: 30px; }
  .report .table {
    float: left;
    width: 100%; }
    .report .table .blue {
      color: #4392F9; }
    .report .table .red {
      color: #FF2742; }
    .report .table .green {
      color: #33CF86; }
    .report .table td div {
      float: left; }
  .report .hide {
    display: none; }

.edit-project {
  width: 920px !important;
  background: #ffffff; }

.newReport {
  background-color: #fff;
  float: left;
  width: 100%;
  height: 100%; }
  .newReport .newReportForm {
    padding: 0 140px 0 80px;
    float: left;
    width: 100%;
    margin-top: 50px; }
    .newReport .newReportForm .ant-row {
      float: left;
      width: 100%; }
    .newReport .newReportForm .newReportFormLeft {
      float: left;
      width: 40%;
      border-right: 1px solid #E3E6ED;
      padding-right: 30px; }
    .newReport .newReportForm .newReportFormRight {
      float: right;
      width: 60%; }
    .newReport .newReportForm label {
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #8c93a8;
      float: left; }
    .newReport .newReportForm h4 {
      font-size: 14px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: #8c93a8;
      line-height: 20px;
      text-align: left; }
    .newReport .newReportForm .newReportFormButton {
      width: 100px;
      height: 32px;
      border-radius: 2px;
      margin-left: 20px; }
    .newReport .newReportForm .operaCol {
      padding-top: 1%; }

.reportAll {
  width: 1620px;
  height: 100%;
  background: #ffffff;
  padding: 0 20px; }
  .reportAll .addNewReport {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFang-SC-Regular;
    font-weight: 400;
    color: #3c4150;
    line-height: 30px; }
    .reportAll .addNewReport span {
      float: right;
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #464a59;
      line-height: 30px; }
  .reportAll .table {
    float: left;
    width: 100%; }
    .reportAll .table .blue {
      color: #4392F9; }
    .reportAll .table .red {
      color: #FF2742; }
    .reportAll .table .green {
      color: #33CF86; }
    .reportAll .table td div {
      float: left; }
  .reportAll .hide {
    display: none; }

.edit-project {
  width: 920px !important;
  background: #ffffff; }

.newReport {
  background-color: #fff;
  float: left;
  width: 100%;
  height: 100%; }
  .newReport .newReportForm {
    padding: 0 140px 0 80px;
    float: left;
    width: 100%;
    margin-top: 50px; }
    .newReport .newReportForm .ant-row {
      float: left;
      width: 100%; }
    .newReport .newReportForm .newReportFormLeft {
      float: left;
      width: 40%;
      border-right: 1px solid #E3E6ED;
      padding-right: 30px; }
    .newReport .newReportForm .newReportFormRight {
      float: right;
      width: 60%; }
    .newReport .newReportForm label {
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #8c93a8;
      float: left; }
    .newReport .newReportForm h4 {
      font-size: 14px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: #8c93a8;
      line-height: 20px;
      text-align: left; }
    .newReport .newReportForm .newReportFormButton {
      width: 100px;
      height: 32px;
      border-radius: 2px;
      margin-left: 20px; }
    .newReport .newReportForm .operaCol {
      padding-top: 1%; }

.manager {
  width: 100%;
  height: 100%;
  background-color: #ffffff; }
  .manager .ant-table-pagination.ant-pagination {
    margin-right: 20px; }

.buildingStructure {
  display: flex;
  width: 100%;
  height: 100%; }
  .buildingStructure .buildingStructureLeft {
    float: left;
    width: 210px;
    padding: 20px;
    height: 100%;
    margin-right: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative; }
    .buildingStructure .buildingStructureLeft .buildingStructureLeftTreeHead {
      float: left;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc; }
      .buildingStructure .buildingStructureLeft .buildingStructureLeftTreeHead .buildingStructureLeftTreeHeadButtom {
        float: right; }
        .buildingStructure .buildingStructureLeft .buildingStructureLeftTreeHead .buildingStructureLeftTreeHeadButtom a {
          font-size: 12px; }
      .buildingStructure .buildingStructureLeft .buildingStructureLeftTreeHead span {
        float: left; }
      .buildingStructure .buildingStructureLeft .buildingStructureLeftTreeHead i {
        line-height: 21px; }
    .buildingStructure .buildingStructureLeft .buildingStructureLeftTreeBottom {
      overflow: scroll;
      position: absolute;
      top: 52px;
      left: 20px;
      bottom: 20px;
      right: 20px;
      margin: auto; }
      .buildingStructure .buildingStructureLeft .buildingStructureLeftTreeBottom li {
        width: 100%; }
    .buildingStructure .buildingStructureLeft .ant-tree {
      float: left;
      width: 100%;
      margin-top: 10px; }
      .buildingStructure .buildingStructureLeft .ant-tree li {
        float: left; }
        .buildingStructure .buildingStructureLeft .ant-tree li span {
          float: left; }
        .buildingStructure .buildingStructureLeft .ant-tree li .ant-tree-node-content-wrapper {
          max-width: 135px; }
          .buildingStructure .buildingStructureLeft .ant-tree li .ant-tree-node-content-wrapper span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%; }
  .buildingStructure .buildingStructureRight {
    float: right;
    flex: 1 1;
    position: relative; }
    .buildingStructure .buildingStructureRight .buildingStructureRightTop,
    .buildingStructure .buildingStructureRight .buildingStructureRightBottom {
      background-color: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 4px; }
    .buildingStructure .buildingStructureRight .buildingStructureRightTop {
      height: 72px; }
      .buildingStructure .buildingStructureRight .buildingStructureRightTop .buildingStructureRightTopContainer {
        float: left; }
    .buildingStructure .buildingStructureRight .buildingStructureRightBottom {
      margin-top: 20px;
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0; }
      .buildingStructure .buildingStructureRight .buildingStructureRightBottom .buildingStructureRightBottomHead {
        width: 100%;
        height: 32px;
        margin-bottom: 15px; }
        .buildingStructure .buildingStructureRight .buildingStructureRightBottom .buildingStructureRightBottomHead .buildingStructureRightBottomHeadLeft {
          float: left;
          width: 50%; }
          .buildingStructure .buildingStructureRight .buildingStructureRightBottom .buildingStructureRightBottomHead .buildingStructureRightBottomHeadLeft button {
            margin-right: 10px;
            float: left; }
        .buildingStructure .buildingStructureRight .buildingStructureRightBottom .buildingStructureRightBottomHead .buildingStructureRightBottomHeadRight {
          float: right; }
          .buildingStructure .buildingStructureRight .buildingStructureRightBottom .buildingStructureRightBottomHead .buildingStructureRightBottomHeadRight button {
            margin-left: 10px; }
      .buildingStructure .buildingStructureRight .buildingStructureRightBottom .ant-table-thead > tr > th {
        background-color: #eff3f8; }
      .buildingStructure .buildingStructureRight .buildingStructureRightBottom .ant-checkbox-inner {
        border-radius: 4px; }
  .buildingStructure .hide {
    display: none; }

.buildingStructureModal {
  float: left; }

.hide {
  display: none; }

.management {
  float: left;
  margin-right: 10px; }
  .management > span {
    color: #3E8FF8; }

.project {
  width: 1620px;
  height: 100%;
  background: #ffffff;
  padding: 0 20px; }
  .project .addNewProject {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFang-SC-Regular;
    font-weight: 400;
    color: #3c4150;
    line-height: 30px; }
    .project .addNewProject span {
      float: right;
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #464a59;
      line-height: 30px; }
  .project .table {
    float: left;
    width: 100%; }
    .project .table .blue {
      color: #4392F9; }
    .project .table .red {
      color: #FF2742; }
    .project .table .green {
      color: #33CF86; }
    .project .table td div {
      float: left; }
  .project .hide {
    display: none; }

.edit-project {
  width: 920px !important;
  background: #ffffff; }

.indexHead {
  margin-top: 16px;
  float: left;
  width: 100%;
  border-bottom: 2px solid #E3E6ED; }
  .indexHead ul {
    float: left;
    margin-bottom: 12px; }
    .indexHead ul li {
      float: left;
      margin-right: 33px; }
      .indexHead ul li span {
        float: left;
        line-height: 32px;
        margin-right: 10px; }
      .indexHead ul li .input {
        float: left; }
      .indexHead ul li .ant-input {
        width: 180px;
        height: 32px; }
      .indexHead ul li .ant-select {
        width: 150px;
        height: 32px; }
  .indexHead .indexHeadRight {
    float: right; }

.account {
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 0 20px; }
  .account .addNewAccount {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFang-SC-Regular;
    font-weight: 400;
    color: #3c4150;
    line-height: 30px; }
    .account .addNewAccount div {
      float: left; }
    .account .addNewAccount span {
      float: right;
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #464a59;
      line-height: 30px; }
  .account .table {
    float: left;
    width: 100%; }
    .account .table .blue {
      color: #4392F9; }
    .account .table .red {
      color: #4392F9; }
    .account .table .green {
      color: #4392F9; }
    .account .table .tableOperation div {
      float: left;
      margin-right: 10px; }
  .account .accountHead {
    margin-top: 16px;
    float: left;
    width: 100%;
    border-bottom: 2px solid #E3E6ED; }
    .account .accountHead ul {
      float: left;
      width: 100%;
      margin-bottom: 12px; }
      .account .accountHead ul li {
        float: left;
        margin-right: 33px; }
        .account .accountHead ul li span {
          float: left;
          line-height: 32px;
          margin-right: 10px; }
        .account .accountHead ul li .input {
          float: left; }
        .account .accountHead ul li .ant-input {
          width: 180px;
          height: 32px; }
        .account .accountHead ul li .ant-select {
          width: 150px;
          height: 32px; }

.accountModal .ant-row {
  margin-bottom: 10px; }
  .accountModal .ant-row .ant-col-4 {
    line-height: 32px; }

.addNewAccountUl li {
  margin-bottom: 10px; }
  .addNewAccountUl li .label {
    line-height: 32px;
    float: left;
    width: 100%;
    text-align: right; }

.newProject {
  background-color: #fff;
  float: left;
  width: 100%;
  height: 100%; }
  .newProject .newProjectForm {
    padding: 0 140px 0 80px;
    float: left;
    width: 100%;
    margin-top: 50px; }
    .newProject .newProjectForm .ant-row {
      float: left;
      width: 100%; }
    .newProject .newProjectForm .newProjectFormLeft {
      float: left;
      width: 40%;
      border-right: 1px solid #E3E6ED;
      padding-right: 30px; }
    .newProject .newProjectForm .newProjectFormRight {
      float: right;
      width: 60%; }
    .newProject .newProjectForm label {
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #8c93a8;
      float: left; }
    .newProject .newProjectForm h4 {
      font-size: 14px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: #8c93a8;
      line-height: 20px;
      text-align: left; }
    .newProject .newProjectForm .newProjectFormButton {
      width: 100px;
      height: 32px;
      border-radius: 2px;
      margin-left: 20px; }

.Shops {
  display: flex;
  width: 100%;
  height: 100%; }
  .Shops .shopsLeft {
    float: left;
    width: 210px;
    padding: 20px;
    height: 100%;
    margin-right: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative; }
    .Shops .shopsLeft .shopsTreeHead {
      float: left;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc; }
      .Shops .shopsLeft .shopsTreeHead .shopsTreeHeadButtom {
        float: right; }
        .Shops .shopsLeft .shopsTreeHead .shopsTreeHeadButtom a {
          font-size: 12px; }
      .Shops .shopsLeft .shopsTreeHead span {
        float: left; }
      .Shops .shopsLeft .shopsTreeHead i {
        line-height: 21px; }
    .Shops .shopsLeft .shopsTreeBottom {
      overflow: auto;
      position: absolute;
      top: 52px;
      left: 20px;
      bottom: 20px;
      right: 20px;
      margin: auto; }
      .Shops .shopsLeft .shopsTreeBottom li {
        width: 100%; }
    .Shops .shopsLeft .ant-tree {
      float: left;
      width: 100%;
      margin-top: 10px; }
      .Shops .shopsLeft .ant-tree li {
        float: left; }
        .Shops .shopsLeft .ant-tree li span {
          float: left; }
        .Shops .shopsLeft .ant-tree li .ant-tree-node-content-wrapper {
          max-width: 135px; }
          .Shops .shopsLeft .ant-tree li .ant-tree-node-content-wrapper span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%; }
  .Shops .shopsRight {
    float: right;
    flex: 1 1;
    position: relative; }
    .Shops .shopsRight .shopsRightTop,
    .Shops .shopsRight .shopsRightBottom {
      background-color: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 4px; }
    .Shops .shopsRight .shopsRightTop {
      height: 72px; }
      .Shops .shopsRight .shopsRightTop .shopsRightTopContainer {
        float: left; }
    .Shops .shopsRight .shopsRightBottom {
      margin-top: 20px;
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0; }
      .Shops .shopsRight .shopsRightBottom .shopsRightBottomHead {
        width: 100%;
        height: 32px;
        margin-bottom: 15px; }
        .Shops .shopsRight .shopsRightBottom .shopsRightBottomHead .shopsRightBottomHeadLeft {
          float: left;
          width: 50%; }
          .Shops .shopsRight .shopsRightBottom .shopsRightBottomHead .shopsRightBottomHeadLeft button {
            margin-right: 10px;
            float: left; }
        .Shops .shopsRight .shopsRightBottom .shopsRightBottomHead .shopsRightBottomHeadRight {
          float: right; }
      .Shops .shopsRight .shopsRightBottom .ant-table-thead > tr > th {
        background-color: #eff3f8; }
      .Shops .shopsRight .shopsRightBottom .ant-checkbox-inner {
        border-radius: 4px; }
      .Shops .shopsRight .shopsRightBottom .colorG {
        color: #41cb74; }
      .Shops .shopsRight .shopsRightBottom .colorR {
        color: red; }
  .Shops .hide {
    display: none; }

.building {
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 0 20px; }
  .building .addNewBuilding {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFang-SC-Regular;
    font-weight: 400;
    color: #3c4150;
    line-height: 30px; }
    .building .addNewBuilding div {
      float: left; }
    .building .addNewBuilding span {
      float: right;
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #464a59;
      line-height: 30px; }
  .building .table {
    float: left;
    width: 100%; }
    .building .table .blue {
      color: #4392F9; }
    .building .table .red {
      color: #4392F9; }
    .building .table .green {
      color: #4392F9; }
  .building .accountHead {
    margin-top: 16px;
    float: left;
    width: 100%;
    border-bottom: 2px solid #E3E6ED; }
    .building .accountHead ul {
      float: left;
      width: 100%;
      margin-bottom: 12px; }
      .building .accountHead ul li {
        float: left;
        margin-right: 33px; }
        .building .accountHead ul li span {
          float: left;
          line-height: 32px;
          margin-right: 10px; }
        .building .accountHead ul li .input {
          float: left; }
        .building .accountHead ul li .ant-input {
          width: 180px;
          height: 32px; }
        .building .accountHead ul li .ant-select {
          width: 150px;
          height: 32px; }

.addNewAccountUl li {
  margin-bottom: 10px; }
  .addNewAccountUl li .label {
    line-height: 32px;
    float: left;
    width: 100%;
    text-align: right; }

.management {
  float: left;
  margin-right: 10px; }
  .management > span {
    color: #3E8FF8; }

.buildingState {
  display: flex;
  width: 100%;
  height: 100%; }
  .buildingState .buildingStateLeft {
    float: left;
    width: 210px;
    padding: 20px;
    height: 100%;
    margin-right: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative; }
    .buildingState .buildingStateLeft .buildingStateLeftTreeHead {
      float: left;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc; }
      .buildingState .buildingStateLeft .buildingStateLeftTreeHead .buildingStateLeftTreeHeadButtom {
        float: right; }
        .buildingState .buildingStateLeft .buildingStateLeftTreeHead .buildingStateLeftTreeHeadButtom a {
          font-size: 12px; }
      .buildingState .buildingStateLeft .buildingStateLeftTreeHead span {
        float: left; }
      .buildingState .buildingStateLeft .buildingStateLeftTreeHead i {
        line-height: 21px; }
    .buildingState .buildingStateLeft .buildingStateLeftTreeBottom {
      overflow: auto;
      position: absolute;
      top: 52px;
      left: 20px;
      bottom: 20px;
      right: 20px;
      margin: auto; }
      .buildingState .buildingStateLeft .buildingStateLeftTreeBottom li {
        width: 100%; }
    .buildingState .buildingStateLeft .ant-tree {
      float: left;
      width: 100%;
      margin-top: 10px; }
      .buildingState .buildingStateLeft .ant-tree li {
        float: left; }
        .buildingState .buildingStateLeft .ant-tree li span {
          float: left; }
        .buildingState .buildingStateLeft .ant-tree li .ant-tree-node-content-wrapper {
          max-width: 135px; }
          .buildingState .buildingStateLeft .ant-tree li .ant-tree-node-content-wrapper span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%; }
  .buildingState .buildingStateRight {
    float: right;
    flex: 1 1;
    position: relative; }
    .buildingState .buildingStateRight .buildingStateRightTop,
    .buildingState .buildingStateRight .buildingStateRightBottom {
      background-color: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 4px; }
    .buildingState .buildingStateRight .buildingStateRightTop {
      height: 72px; }
      .buildingState .buildingStateRight .buildingStateRightTop .buildingStateRightTopContainer {
        float: left; }
    .buildingState .buildingStateRight .buildingStateRightBottom {
      margin-top: 20px;
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0; }
      .buildingState .buildingStateRight .buildingStateRightBottom .buildingStateRightBottomHead {
        width: 100%;
        height: 32px;
        margin-bottom: 15px; }
        .buildingState .buildingStateRight .buildingStateRightBottom .buildingStateRightBottomHead .buildingStateRightBottomHeadLeft {
          float: left;
          width: 50%; }
          .buildingState .buildingStateRight .buildingStateRightBottom .buildingStateRightBottomHead .buildingStateRightBottomHeadLeft button {
            margin-right: 10px;
            float: left; }
        .buildingState .buildingStateRight .buildingStateRightBottom .buildingStateRightBottomHead .buildingStateRightBottomHeadRight {
          float: right; }
      .buildingState .buildingStateRight .buildingStateRightBottom .ant-table-thead > tr > th {
        background-color: #eff3f8; }
      .buildingState .buildingStateRight .buildingStateRightBottom .ant-checkbox-inner {
        border-radius: 4px; }
  .buildingState .hide {
    display: none; }

.buildingStateModal {
  float: left; }

.hide {
  display: none; }

.departmentState {
  display: flex;
  width: 100%;
  height: 100%; }
  .departmentState .departmentStateLeft {
    float: left;
    width: 210px;
    padding: 20px;
    height: 100%;
    margin-right: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative; }
    .departmentState .departmentStateLeft .departmentStateLeftTreeHead {
      float: left;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc; }
      .departmentState .departmentStateLeft .departmentStateLeftTreeHead .departmentStateLeftTreeHeadButtom {
        float: right; }
        .departmentState .departmentStateLeft .departmentStateLeftTreeHead .departmentStateLeftTreeHeadButtom a {
          font-size: 12px; }
      .departmentState .departmentStateLeft .departmentStateLeftTreeHead span {
        float: left; }
      .departmentState .departmentStateLeft .departmentStateLeftTreeHead i {
        line-height: 21px; }
    .departmentState .departmentStateLeft .departmentStateLeftTreeBottom {
      overflow: scroll;
      position: absolute;
      top: 52px;
      left: 20px;
      bottom: 20px;
      right: 20px;
      margin: auto; }
      .departmentState .departmentStateLeft .departmentStateLeftTreeBottom li {
        width: 100%; }
    .departmentState .departmentStateLeft .ant-tree {
      float: left;
      width: 100%;
      margin-top: 10px; }
      .departmentState .departmentStateLeft .ant-tree li {
        float: left; }
        .departmentState .departmentStateLeft .ant-tree li span {
          float: left; }
        .departmentState .departmentStateLeft .ant-tree li .ant-tree-node-content-wrapper {
          max-width: 107px; }
          .departmentState .departmentStateLeft .ant-tree li .ant-tree-node-content-wrapper span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%; }
  .departmentState .departmentStateRight {
    float: right;
    flex: 1 1;
    position: relative; }
    .departmentState .departmentStateRight .departmentStateRightTop,
    .departmentState .departmentStateRight .departmentStateRightBottom {
      background-color: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 4px; }
    .departmentState .departmentStateRight .departmentStateRightTop {
      height: 72px; }
      .departmentState .departmentStateRight .departmentStateRightTop .departmentStateRightTopContainer {
        float: left; }
    .departmentState .departmentStateRight .departmentStateRightBottom {
      margin-top: 20px;
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0; }
      .departmentState .departmentStateRight .departmentStateRightBottom .departmentStateRightBottomHead {
        width: 100%;
        height: 32px;
        margin-bottom: 15px; }
        .departmentState .departmentStateRight .departmentStateRightBottom .departmentStateRightBottomHead .departmentStateRightBottomHeadLeft {
          float: left;
          width: 50%; }
          .departmentState .departmentStateRight .departmentStateRightBottom .departmentStateRightBottomHead .departmentStateRightBottomHeadLeft button {
            margin-right: 10px;
            float: left; }
        .departmentState .departmentStateRight .departmentStateRightBottom .departmentStateRightBottomHead .departmentStateRightBottomHeadRight {
          float: right; }
      .departmentState .departmentStateRight .departmentStateRightBottom .ant-table-thead > tr > th {
        background-color: #eff3f8; }
      .departmentState .departmentStateRight .departmentStateRightBottom .ant-checkbox-inner {
        border-radius: 4px; }
  .departmentState .hide {
    display: none; }

.departmentStateModal {
  float: left; }

.hide {
  display: none; }

.index {
  width: 1620px;
  height: 100%;
  background: #ffffff;
  padding: 0 20px; }
  .index .addNewProject {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFang-SC-Regular;
    font-weight: 400;
    color: #3c4150;
    line-height: 30px; }
    .index .addNewProject span {
      float: right;
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #464a59;
      line-height: 30px; }
  .index .table {
    float: left;
    width: 100%; }
    .index .table .blue {
      color: #4392F9; }
    .index .table .red {
      color: #4392F9; }
    .index .table .green {
      color: #4392F9; }

.indexHead {
  margin-top: 16px;
  float: left;
  width: 100%;
  border-bottom: 2px solid #E3E6ED; }
  .indexHead ul {
    float: left;
    margin-bottom: 12px; }
    .indexHead ul li {
      float: left;
      margin-right: 33px; }
      .indexHead ul li span {
        float: left;
        line-height: 32px;
        margin-right: 10px; }
      .indexHead ul li .input {
        float: left; }
      .indexHead ul li .ant-input {
        width: 180px;
        height: 32px; }
      .indexHead ul li .ant-select {
        width: 150px;
        height: 32px; }
  .indexHead .indexHeadRight {
    float: right; }

.management {
  float: left;
  margin-right: 10px; }
  .management > span {
    color: #3E8FF8; }

.byitem {
  display: flex;
  width: 100%;
  height: 100%; }
  .byitem .byitemLeft {
    float: left;
    width: 210px;
    padding: 20px;
    height: 100%;
    margin-right: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
    .byitem .byitemLeft .byitemLeftTreeHead {
      float: left;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc; }
      .byitem .byitemLeft .byitemLeftTreeHead span {
        float: left; }
      .byitem .byitemLeft .byitemLeftTreeHead i {
        float: right;
        line-height: 21px; }
    .byitem .byitemLeft .ant-tree {
      float: left;
      width: 100%;
      margin-top: 10px; }
      .byitem .byitemLeft .ant-tree li {
        float: left;
        width: 100%; }
        .byitem .byitemLeft .ant-tree li span {
          float: left; }
        .byitem .byitemLeft .ant-tree li .ant-tree-node-content-wrapper {
          max-width: 135px; }
          .byitem .byitemLeft .ant-tree li .ant-tree-node-content-wrapper span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%; }
  .byitem .byitemRight {
    float: right;
    flex: 1 1;
    position: relative; }
    .byitem .byitemRight .byitemRightTop,
    .byitem .byitemRight .byitemRightBottom {
      background-color: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 4px; }
    .byitem .byitemRight .byitemRightTop {
      height: 72px; }
      .byitem .byitemRight .byitemRightTop .byitemRightTopContainer {
        float: left; }
    .byitem .byitemRight .byitemRightBottom {
      margin-top: 20px;
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0; }
      .byitem .byitemRight .byitemRightBottom .byitemRightBottomHead {
        width: 100%;
        height: 32px;
        margin-bottom: 15px; }
        .byitem .byitemRight .byitemRightBottom .byitemRightBottomHead .byitemRightBottomHeadLeft {
          float: left; }
          .byitem .byitemRight .byitemRightBottom .byitemRightBottomHead .byitemRightBottomHeadLeft button {
            margin-right: 10px; }
        .byitem .byitemRight .byitemRightBottom .byitemRightBottomHead .byitemRightBottomHeadRight {
          float: right; }
      .byitem .byitemRight .byitemRightBottom .ant-table-thead > tr > th {
        background-color: #eff3f8; }
      .byitem .byitemRight .byitemRightBottom .ant-checkbox-inner {
        border-radius: 4px; }
  .byitem .hide {
    display: none; }

.byitemModal {
  float: left; }

.hide {
  display: none; }

.buildingStructureAddNode {
  display: flex;
  width: 100%;
  height: 100%; }
  .buildingStructureAddNode .buildingStructureAddNodeRight {
    float: right;
    flex: 1 1;
    position: relative; }
    .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeSearch,
    .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeTable {
      background-color: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 4px; }
    .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeSearch {
      height: 72px; }
      .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeSearch .buildingStructureAddNodeSearchContainer {
        float: left; }
    .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeTable {
      margin-top: 20px;
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0; }
      .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeTable .buildingStructureAddNodeTableHead {
        width: 100%;
        height: 32px;
        margin-bottom: 15px; }
        .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeTable .buildingStructureAddNodeTableHead .buildingStructureAddNodeTableHeadLeft {
          float: left; }
          .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeTable .buildingStructureAddNodeTableHead .buildingStructureAddNodeTableHeadLeft button {
            margin-right: 10px; }
        .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeTable .buildingStructureAddNodeTableHead .buildingStructureAddNodeTableHeadRight {
          float: right; }
      .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeTable .ant-table-wrapper .ant-btn-primary {
        padding: 0 2px;
        margin-right: 5px; }
      .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeTable .ant-table-wrapper .ant-btn > .anticon + span {
        margin-left: 0; }
      .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeTable .ant-table-thead > tr > th {
        background-color: #eff3f8; }
      .buildingStructureAddNode .buildingStructureAddNodeRight .buildingStructureAddNodeTable .ant-checkbox-inner {
        border-radius: 4px; }
  .buildingStructureAddNode .hide {
    display: none; }

.buildingStructureAddNodeModal {
  float: left; }

.buildingStructureAddNodeModalUl li {
  margin-bottom: 10px; }

.buildingStructureAddNodeModalSeletNode .ant-tree-title {
  font-size: 10px; }

.hide {
  display: none; }

.buildingStateAddNode {
  display: flex;
  width: 100%;
  height: 100%; }
  .buildingStateAddNode .buildingStateAddNodeRight {
    float: right;
    flex: 1 1;
    position: relative; }
    .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeSearch,
    .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeTable {
      background-color: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 4px; }
    .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeSearch {
      height: 72px; }
      .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeSearch .buildingStateAddNodeSearchContainer {
        float: left; }
    .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeTable {
      margin-top: 20px;
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0; }
      .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeTable .buildingStateAddNodeTableHead {
        width: 100%;
        height: 32px;
        margin-bottom: 15px; }
        .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeTable .buildingStateAddNodeTableHead .buildingStateAddNodeTableHeadLeft {
          float: left; }
          .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeTable .buildingStateAddNodeTableHead .buildingStateAddNodeTableHeadLeft button {
            margin-right: 10px; }
        .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeTable .buildingStateAddNodeTableHead .buildingStateAddNodeTableHeadRight {
          float: right; }
      .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeTable .ant-table-wrapper .ant-btn-primary {
        padding: 0 2px;
        margin-right: 5px; }
      .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeTable .ant-table-wrapper .ant-btn > .anticon + span {
        margin-left: 0; }
      .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeTable .ant-table-thead > tr > th {
        background-color: #eff3f8; }
      .buildingStateAddNode .buildingStateAddNodeRight .buildingStateAddNodeTable .ant-checkbox-inner {
        border-radius: 4px; }
  .buildingStateAddNode .hide {
    display: none; }

.buildingStateAddNodeModal {
  float: left; }

.buildingStateAddNodeModalUl li {
  margin-bottom: 10px; }

.buildingStateAddNodeModalSeletNode .ant-tree-title {
  font-size: 10px; }

.hide {
  display: none; }

.departmentStateAddNode {
  display: flex;
  width: 100%;
  height: 100%; }
  .departmentStateAddNode .departmentStateAddNodeRight {
    float: right;
    flex: 1 1;
    position: relative; }
    .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeSearch,
    .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeTable {
      background-color: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 4px; }
    .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeSearch {
      height: 72px; }
      .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeSearch .departmentStateAddNodeSearchContainer {
        float: left; }
    .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeTable {
      margin-top: 20px;
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0; }
      .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeTable .departmentStateAddNodeTableHead {
        width: 100%;
        height: 32px;
        margin-bottom: 15px; }
        .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeTable .departmentStateAddNodeTableHead .departmentStateAddNodeTableHeadLeft {
          float: left; }
          .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeTable .departmentStateAddNodeTableHead .departmentStateAddNodeTableHeadLeft button {
            margin-right: 10px; }
        .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeTable .departmentStateAddNodeTableHead .departmentStateAddNodeTableHeadRight {
          float: right; }
      .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeTable .ant-table-wrapper .ant-btn-primary {
        padding: 0 2px;
        margin-right: 5px; }
      .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeTable .ant-table-wrapper .ant-btn > .anticon + span {
        margin-left: 0; }
      .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeTable .ant-table-thead > tr > th {
        background-color: #eff3f8; }
      .departmentStateAddNode .departmentStateAddNodeRight .departmentStateAddNodeTable .ant-checkbox-inner {
        border-radius: 4px; }
  .departmentStateAddNode .hide {
    display: none; }

.departmentStateAddNodeModal {
  float: left; }

.departmentStateAddNodeModalUl li {
  margin-bottom: 10px; }

.departmentStateAddNodeModalSeletNode .ant-tree-title {
  font-size: 10px; }

.hide {
  display: none; }

.newProject {
  background-color: #fff;
  float: left;
  width: 100%;
  height: 100%; }
  .newProject .newProjectForm {
    padding: 0 140px 0 80px;
    float: left;
    width: 100%;
    margin-top: 50px; }
    .newProject .newProjectForm .ant-row {
      float: left;
      width: 100%; }
    .newProject .newProjectForm .newProjectFormLeft {
      float: left;
      width: 40%;
      border-right: 1px solid #E3E6ED;
      padding-right: 30px; }
    .newProject .newProjectForm .newProjectFormRight {
      float: right;
      width: 60%; }
    .newProject .newProjectForm label {
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #8c93a8;
      float: left; }
    .newProject .newProjectForm h4 {
      font-size: 14px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: #8c93a8;
      line-height: 20px;
      text-align: left; }
    .newProject .newProjectForm .newProjectFormButton {
      width: 100px;
      height: 32px;
      border-radius: 2px;
      margin-left: 20px; }

.byitemModal {
  float: left; }

.hide {
  display: none; }

.newProject {
  background-color: #fff;
  float: left;
  width: 100%;
  height: 100%; }
  .newProject .newProjectForm {
    padding: 0 140px 0 80px;
    float: left;
    width: 100%;
    margin-top: 50px; }
    .newProject .newProjectForm .ant-row {
      float: left;
      width: 100%; }
    .newProject .newProjectForm .newProjectFormLeft {
      float: left;
      width: 40%;
      border-right: 1px solid #E3E6ED;
      padding-right: 30px; }
    .newProject .newProjectForm .newProjectFormRight {
      float: right;
      width: 60%; }
    .newProject .newProjectForm label {
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #8c93a8;
      float: left; }
    .newProject .newProjectForm h4 {
      font-size: 14px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: #8c93a8;
      line-height: 20px;
      text-align: left; }
    .newProject .newProjectForm .newProjectFormButton {
      width: 100px;
      height: 32px;
      border-radius: 2px;
      margin-left: 20px; }

.byitemModal {
  float: left; }

.hide {
  display: none; }

.nav {
  height: 100%; }
  .nav nav {
    width: 240px;
    height: 100%;
    background: #2E3142;
    float: left; }
    .nav nav .navHead {
      width: 240px;
      height: 170px;
      border-bottom: 2px solid #212332;
      float: left; }
      .nav nav .navHead img {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        padding: 9px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin-top: 30px; }
      .nav nav .navHead p {
        font-size: 12px;
        font-family: Helvetica;
        color: white;
        line-height: 14px;
        float: left;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 0; }
    .nav nav ul {
      float: left;
      margin: 0;
      margin-top: 48px; }
      .nav nav ul li {
        float: left;
        width: 100%;
        height: 40px;
        margin-bottom: 5px; }
        .nav nav ul li a {
          float: left;
          width: 100%;
          line-height: 40px; }
          .nav nav ul li a span {
            font-size: 16px;
            font-family: 'STHeitiSC-Medium';
            font-weight: 500;
            color: white;
            line-height: 40px;
            float: left; }
          .nav nav ul li a i {
            float: left;
            width: 30px;
            margin-left: 30px;
            line-height: 40px;
            color: white; }
        .nav nav ul li a:hover {
          text-decoration: none; }
    .nav nav .active {
      background-color: #000000;
      border-left: 4px solid #ffffff; }
  .nav .user {
    height: 60px;
    position: absolute;
    left: 240px;
    right: 0;
    top: 0;
    background: white;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05); }
    .nav .user .userDiv {
      float: right;
      padding: 6px 30px; }
      .nav .user .userDiv .ant-dropdown-trigger {
        float: left; }
      .nav .user .userDiv img {
        width: 36px;
        height: 36px;
        float: left; }
      .nav .user .userDiv .userHead {
        border-radius: 50%;
        overflow: hidden;
        background-color: #a5a5a5; }
      .nav .user .userDiv .imgDown {
        float: left;
        line-height: 36px;
        margin-left: 20px; }
      .nav .user .userDiv span {
        float: left;
        margin-left: 10px;
        font-size: 14px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: #666666;
        line-height: 36px; }

