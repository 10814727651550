.nav {
    height: 100%;

    nav {
        width: 240px;
        height: 100%;
        background: #2E3142;
        float: left;

        .navHead {
            width: 240px;
            height: 170px;
            border-bottom: 2px solid #212332;
            float: left;

            img {
                width: 82px;
                height: 82px;
                border-radius: 50%;
                padding: 9px;
                border: 1px solid rgba(255, 255, 255, 0.2);
                margin-top: 30px;
                // float: left;

            }

            p {
                font-size: 12px;
                font-family: Helvetica;
                color: rgba(255, 255, 255, 1);
                line-height: 14px;
                float: left;
                width: 100%;
                margin-top: 15px;
                margin-bottom: 0;
            }
        }

        ul {
            float: left;
            margin: 0;
            margin-top: 48px;

            li {
                float: left;
                width: 100%;
                height: 40px;
                margin-bottom: 5px;

                a {
                    // padding: 13px 30px;
                    float: left;
                    width: 100%;
                    line-height: 40px;

                    span {
                        // height: 16px;
                        font-size: 16px;
                        font-family: 'STHeitiSC-Medium';
                        font-weight: 500;
                        color: rgba(255, 255, 255, 1);
                        line-height: 40px;
                        float: left;
                    }

                    i {
                        float: left;
                        width: 30px;
                        margin-left: 30px;
                        line-height: 40px;
                        color: rgba(255, 255, 255, 1);
                    }

                }

                a:hover {
                    text-decoration: none;
                }
            }
        }

        .active {
            background-color: #000000;
            border-left: 4px solid #ffffff;
        }
    }

    .user {
        // float: left;
        // width: 1680px;
        height: 60px;
        position: absolute;
        left: 240px;
        right: 0;
        top: 0;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);

        .userDiv {
            float: right;
            // margin-right: 10px;
            padding: 6px 30px;

            .ant-dropdown-trigger {
                float: left;
            }

            img {
                width: 36px;
                height: 36px;
                float: left;

            }

            .userHead {
                border-radius: 50%;
                overflow: hidden;
                background-color: #a5a5a5;
            }

            .imgDown {
                float: left;
                line-height: 36px;
                margin-left: 20px;
            }

            span {
                float: left;
                margin-left: 10px;
                font-size: 14px;
                font-family: PingFang-SC-Medium;
                font-weight: 500;
                color: #666666;
                line-height: 36px;
            }
        }
    }
}