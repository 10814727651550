.indexHead {
    margin-top: 16px;
    float: left;
    width: 100%;
    border-bottom: 2px solid #E3E6ED;

    ul {
        float: left;
        margin-bottom: 12px;

        li {
            float: left;
            margin-right: 33px;

            span {
                float: left;
                line-height: 32px;
                margin-right: 10px;
            }

            .input {
                float: left;
            }

            .ant-input {
                width: 180px;
                height: 32px;
            }

            .ant-select {
                width: 150px;
                height: 32px;
                // background: rgba(255, 255, 255, 1);
                // border-radius: 2px;
                // border: 1px solid rgba(227, 230, 237, 1);
            }
        }
    }
    .indexHeadRight{
        float: right;
    }
}