.byitem {
    display: flex;
    width: 100%;
    height: 100%;

    .byitemLeft {
        float: left;
        width: 210px;
        padding: 20px;
        height: 100%;
        margin-right: 20px;
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        .byitemLeftTreeHead {
            float: left;
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;

            span {
                float: left;
            }

            i {
                float: right;
                line-height: 21px;
            }
        }

        .ant-tree {
            float: left;
            width: 100%;
            margin-top: 10px;

            li {
                float: left;
                width: 100%;
                span {
                    float: left;
                }

                .ant-tree-node-content-wrapper {
                    max-width: 135px;

                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                    }

                }
            }
        }
    }

    .byitemRight {
        float: right;
        flex: 1;
        position: relative;

        .byitemRightTop,
        .byitemRightBottom {
            background-color: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            padding: 20px;
            border-radius: 4px;
        }

        .byitemRightTop {
            height: 72px;

            .byitemRightTopContainer {
                float: left;
            }
        }

        .byitemRightBottom {
            margin-top: 20px;
            position: absolute;
            top: 60px;
            bottom: 0;
            left: 0;
            right: 0;

            .byitemRightBottomHead {
                width: 100%;
                height: 32px;
                margin-bottom: 15px;

                .byitemRightBottomHeadLeft {
                    float: left;

                    button {
                        margin-right: 10px;
                    }
                }

                .byitemRightBottomHeadRight {
                    float: right;

                }
            }

            .ant-table-thead>tr>th {
                background-color: #eff3f8;
            }

            .ant-checkbox {
                // box-shadow: 0 0 3px 0px #cacaca;
            }

            .ant-checkbox-inner {
                border-radius: 4px;
                // background-color: #cacaca;
            }

        }
    }

    .hide {
        display: none;
    }
}