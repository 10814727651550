.building {
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 0 20px;

    .addNewBuilding {
        float: left;
        width: 100%;
        text-align: left;
        margin-top: 10px;

        font-size: 14px;
        font-family: PingFang-SC-Regular;
        font-weight: 400;
        color: #3c4150;
        line-height: 30px;
div{
    float: left;
}
        span {
            float: right;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: #464a59;
            line-height: 30px;
        }
    }

    .table {
        float: left;
        width: 100%;

        .blue {
            color: #4392F9;
        }

        .red {
            color: #4392F9;
        }

        .green {
            color: #4392F9;
        }
    }

    .accountHead {
        margin-top: 16px;
        float: left;
        width: 100%;
        border-bottom: 2px solid #E3E6ED;

        ul {
            float: left;
            width: 100%;
            margin-bottom: 12px;

            li {
                float: left;
                margin-right: 33px;

                span {
                    float: left;
                    line-height: 32px;
                    margin-right: 10px;
                }

                .input {
                    float: left;
                }

                .ant-input {
                    width: 180px;
                    height: 32px;
                }

                .ant-select {
                    width: 150px;
                    height: 32px;
                    // background: rgba(255, 255, 255, 1);
                    // border-radius: 2px;
                    // border: 1px solid rgba(227, 230, 237, 1);
                }
            }
        }
    }


}