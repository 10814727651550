.newProject {
    background-color: #fff;
    float: left;
    width: 100%;
    height: 100%;
    .newProjectForm {
        padding: 0 140px 0 80px;
        float: left;
        width: 100%;
        margin-top: 50px;

        .ant-row {
            float: left;
            width: 100%;
        }

        .newProjectFormLeft {
            float: left;
            width: 40%;
            border-right: 1px solid #E3E6ED;
            padding-right: 30px;
        }

        .newProjectFormRight {
            float: right;
            width: 60%;
        }

        label {
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: rgba(140, 147, 168, 1);
            // line-height: 20px;
            float: left;
        }

        h4 {
            font-size: 14px;
            font-family: PingFang-SC-Bold;
            font-weight: bold;
            color: rgba(140, 147, 168, 1);
            line-height: 20px;
            text-align: left;
        }

        .newProjectFormButton {
            width: 100px;
            height: 32px;
            // background: rgba(97, 136, 228, 1);
            border-radius: 2px;
            margin-left: 20px;
        }
    }

}