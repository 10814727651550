.departmentStateAddNodeModal {
    float: left;


}

.departmentStateAddNodeModalUl {
    li {
        margin-bottom: 10px;

    }
}

.departmentStateAddNodeModalSeletNode {
    .ant-tree-title {
        font-size: 10px;
    }
}


.hide {
    display: none;
}