.buildingState {
    display: flex;
    width: 100%;
    height: 100%;

    .buildingStateLeft {
        float: left;
        width: 210px;
        padding: 20px;
        height: 100%;
        margin-right: 20px;
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        position: relative;

        .buildingStateLeftTreeHead {
            float: left;
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;

            .buildingStateLeftTreeHeadButtom {
                float: right;

                a {
                    font-size: 12px;
                }
            }

            span {
                float: left;
            }

            i {
                // float: right;
                line-height: 21px;
            }
        }

        .buildingStateLeftTreeBottom {
            overflow: auto;
            position: absolute;
            top: 52px;
            left: 20px;
            bottom: 20px;
            right: 20px;
            margin: auto;

            li {
                width: 100%;

            }
        }

        .ant-tree {
            float: left;
            width: 100%;
            margin-top: 10px;

            li {
                float: left;

                span {
                    float: left;

                }

                .ant-tree-node-content-wrapper {
                    max-width: 135px;

                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                    }

                }
            }
        }
    }

    .buildingStateRight {
        float: right;
        flex: 1;
        position: relative;

        .buildingStateRightTop,
        .buildingStateRightBottom {
            background-color: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            padding: 20px;
            border-radius: 4px;
        }

        .buildingStateRightTop {
            height: 72px;

            .buildingStateRightTopContainer {
                float: left;
            }
        }

        .buildingStateRightBottom {
            margin-top: 20px;
            position: absolute;
            top: 60px;
            bottom: 0;
            left: 0;
            right: 0;

            .buildingStateRightBottomHead {
                width: 100%;
                height: 32px;
                margin-bottom: 15px;

                .buildingStateRightBottomHeadLeft {
                    float: left;
                    width: 50%;

                    button {
                        margin-right: 10px;
                        float: left;
                    }
                }

                .buildingStateRightBottomHeadRight {
                    float: right;

                }
            }

            .ant-table-thead>tr>th {
                background-color: #eff3f8;
            }

            .ant-checkbox {
                // box-shadow: 0 0 3px 0px #cacaca;
            }

            .ant-checkbox-inner {
                border-radius: 4px;
                // background-color: #cacaca;
            }

        }
    }

    .hide {
        display: none;
    }
}