.index{
    .indexLeft{
        width: 50%;
         height: auto;
         float: left;
         margin-top:50px;
         h1{
            color: #6188e4;
            margin-bottom:50px;
        }
          img{
              width:100%;
              height:100%
          }
    }
    .indexRight{
        float: left;
        margin-top:50px;
        margin-left:100px;
        h1{
            color: #6188e4;
            margin-bottom:170px;
        }
        .Qrcode{
            width:200px;
            height:200px;
            margin:0 20px;
              border: 1px solid #ccc;
              float:left;
              img{
                  width:100%;
                  height:100%;
              }
        }
    }
}